// Only one item MUST have the "default: true" key

module.exports = {
  en: {
    default: true,
    path: 'en',
    locale: 'en-US',
    dateFormat: 'DD/MM/YYYY',
    siteLanguage: 'en',
    ogLanguage: 'en_US',
    defaultTitle: 'VeloMelon',
    defaultDescription: 'Our life bike adventure',
    aboutUs: 'About Us',
    europe: 'Europe',
    poland: 'Poland',
    memories: 'memories',
    bike: 'Bike',
    usa: 'USA',
    preparation: 'Preparation',
    florida: 'Florida',
    virginia: 'Virginia',
    southamerica: 'South America',
    argentina: 'Argentina',
    california: 'California',
  },
  pl: {
    path: 'pl',
    locale: 'pl-PL',
    dateFormat: 'DD.MM.YYYY',
    siteLanguage: 'pl',
    ogLanguage: 'pl_PL',
    defaultTitle: 'VeloMelon',
    defaultDescription: 'Nasza wspólna rowerowa przygoda',
    aboutUs: 'O Nas',
    europe: 'Europa',
    poland: 'Polska',
    memories: 'Wspomnienia',
    bike: 'Rower',
    usa: 'USA',
    preparation: 'Przygotowanie',
    florida: 'Floryda',
    virginia: 'Wirginia',
    southamerica: 'Ameryka Płd',
    argentina: 'Argentyna',
    california: 'Kalifornia',
  },
};
